<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
        :transfer="false"
    >
        <teamRankingEdit :sport_id="sport_id" :team_ranking_id="edit_id" :team_ranking_type='type' @closePage="closeEditPage"></teamRankingEdit>
    </Drawer> 

    <!-- <Modal v-model="modal_edit_team" draggable title="编辑战队" footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <span>编辑队伍</span>
        </div>
        <div class="modal-div-containter">
            <teamEdit :sport_id="sport_id" :team_id="modal_edit_team_id" @closePage="closeEditModalTeam"></teamEdit>
        </div>
    </Modal> -->

    <Row style="margin-bottom:10px;" :gutter="2" align="middle">
        <Col span="4" style="text-align: left">
            <Select v-model="type" @on-change='onTypeChange' >
                <Option v-for="item in team_ranking_types" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
        </Col>
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        
        <Col span="6" style="text-align: left">
            <DatePicker format="yyyy-MM-dd HH:mm" :options="date_options" v-model="datarange" type="daterange" placement="bottom-end"
                 placeholder="日期范围" style="width: 100%" @on-change='onDatarangeChange' editable></DatePicker>
        </Col>
        <Col span="4" style="text-align: left">
            <span class="text-xs text-gray-500">队伍id:</span>
            <InputNumber v-model="team_id" placeholder="队伍id"  @on-change="onTeamidChange" />
        </Col>
        <Col span="6" style="text-align: right">
            <Button type="success" @click="openEdit" >新增</Button>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getTeamRanking, } from '@/api/ballsports/base/team_rankings';
import teamRankingEdit from './edit.vue';
import fitTable from '../../basic/table.vue';
import teamEdit from '../team/edit.vue';
import moment from 'moment';

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_id = params.row.id;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "team-rankings-list",
    props: {
        sport_id: Number,   // 运动id
    },
    components: {
        // thirdPlayerHonotSearch,
        // team_rankingEdit,
        teamRankingEdit,
        fitTable,
        teamEdit,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            edit_id: 0,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            modal_add_relation: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width:100,
                },
                {
                    title: '发布时间',
                    width: 100,
                    align: 'center',
                    key: 'pub_time',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', currentRow.pub_time ? moment.unix(currentRow.pub_time).format('YYYY-MM-DD'): '' )
                    }
                },
                {
                    title: '球队名称',
                    align: 'left',
                    key: 'team_name',
                    minWidth: 100,
                },
                {
                    title: '关联球队',
                    align: 'left',
                    key: 'team',
                    minWidth: 180,
                    render: function (h, params) {
                        let currentRow = params.row
                        let team = currentRow.team 
                        return h(
                            'Row', team.id>0 ? [
                                h("Col", 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } },  team.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    // self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } }, team.name,  ),
                                    ]
                                ,),
                            ] : '无'
                        )
                    }
                },
                {
                    title: '国家',
                    align: 'left',
                    key: 'country',
                    minWidth: 80,
                },
                {
                    title: '顺序',
                    align: 'left',
                    key: 'order',
                    minWidth: 80,
                },
                {
                    title: '排名',
                    align: 'left',
                    key: 'ranking',
                    minWidth: 80,
                },
                {
                    title: '积分',
                    align: 'left',
                    key: 'points',
                    minWidth: 80,
                },
                {
                    title: '前一个积分',
                    align: 'left',
                    key: 'previous_points',
                    minWidth: 80,
                },
                {
                    title: '名次变化',
                    align: 'left',
                    key: 'position_changed',
                    minWidth: 80,
                },
                
                
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    width: 100,
                    render (h, params) {
                        return [ editButton(self, h, params), ]// delButton(self, h, params) ]
                    }
                }
            ],
            data: [],
            modal_edit_team: false,
            modal_edit_team_id: 0,
            type: 1,
            team_ranking_types: [],
            team_id: -1,
            start_date: 0, // parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            end_date  : 0, // parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            datarange : [], // [new Date(new Date().toLocaleDateString()),new Date(new Date().toLocaleDateString())],
            date_options: {
                shortcuts: [
                    {
                        text: '1个月',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '1周',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '6小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '3小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 3);
                            return [start, end];
                        }
                    },
                    {
                        text: '30分钟',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 1800 * 1000);
                            return [start, end];
                        }
                    },
                    {
                        text: '昨天',
                        value () {
                            const end = new Date(new Date().toLocaleDateString());
                            const start = new Date();
                            start.setTime(end.getTime() - 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '今天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            end.setTime(start.getTime() + 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '明天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            start.setTime(start.getTime() + 3600 * 1000 * 24);
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            return [start, end];
                        }
                    },
                    
                ]
            },
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onTypeChange () {
            this.current_page = 1;
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onTeamidChange(search_key){
            this.current_page = 1;
            this.team_id = parseInt(search_key)
            this.getData();
        },
        onDatarangeChange(value){
            // console.log("5 change", value);
            let self = this;
            self.current_page = 1;
            if (value.length > 0){
                self.start_date = parseInt( Date.parse(value[0]) * 0.001 );
                self.end_date   = parseInt( Date.parse(value[1]) * 0.001 );
                // console.log( typeof( self.start_date ), self.start_date );
            }else{
                self.start_date = 0;
                self.end_date = 0;
            }
            self.start_date = self.start_date || 0;
            self.end_date   = self.end_date || 0;
            self.getData();
        },
        // end
        getData () {
            let self = this;
            this.loading = true;
            // 搜索日期
            let lang = sessionStorage.getItem("lang");
            // console.log("lang: ", lang);
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 
            var params = {
                sport_id: self.sport_id,
                type: self.type,
                search_key: self.search_key,
                page: self.current_page,
                start_time: self.start_date,
                end_time  : self.end_date,
                team_id: isNaN(self.team_id)? -1: self.team_id, 
                lang: lang,
            }
            getTeamRanking(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        
        
        openEdit(){
            let self = this;
            self.edit_id=0;
            self.drawer_edit = true;
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
        },
        // end
        editTeam(_id){
            let self = this;
            self.modal_edit_team_id = _id;
            self.modal_edit_team = true;
        },
        // end
        closeEditModalTeam(){
            let self = this;
            self.modal_edit_team = false;
            self.modal_edit_team_id = 0;
        },
        // end
    },
    mounted () {
        // console.log(this.sport_id)
        let self = this;
        // console.log(self.$conf.ballsports.team_ranking_types)
        self.team_ranking_types = self.$conf.ballsports.team_ranking_types[self.sport_id] || []
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>