<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Tabs value="1">
            <TabPane label="基础信息" name="1">
            <Form label-position="left" :label-width="80" >
                <FormItem label="id: " v-if="data.id">
                    <Row>
                        <Col span="20" >
                            <Input v-model="data.id" disabled></Input>
                        </Col>
                    </Row>
                </FormItem>
                

                <FormItem label="球队名称: ">
                    <Row>
                        <Col span="20" >
                            <Input disabled v-model="data.team_name" :class="locks[String(type)+'-1-'+'season'] === 1? 'locked-input': '' " ></Input>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'season']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_ranking_id" :type="type" :sub_type="1" _key="season"></Lock>
                        </Col>
                    </Row>
                </FormItem>

                
                <FormItem label="球队: ">
                    <Row>
                        <Col span="20" >
                            <teamSearch v-model="data.team" :sport_id="sport_id" :classname="locks[String(type)+'-1-'+'team'] === 1? 'locked-input': '' "></teamSearch>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'ranking']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_ranking_id" :type="type" :sub_type="1" _key="team"></Lock>
                        </Col>
                    </Row>
                </FormItem>
                
                <!-- <FormItem label="是否删除: ">
                    <Row>
                        <Col span="20" >
                            <Select v-model="data.deleted" :class="locks[String(type) + '-1-' + 'deleted'] === 1? 'locked-select': '' ">
                                <Option :value="1" key="1">是</Option>
                                <Option :value="0" key="0">否</Option>
                            </Select>
                        </Col>
                        <Col span="2" offset='1'>
                            <Lock v-model="locks[String(type)+'-1-'+'deleted']" :sport_id="sport_id" :obj_type="obj_type" :obj_id="team_ranking_id" :type="type" :sub_type="1" _key="deleted"></Lock>
                        </Col>
                    </Row>
                </FormItem> -->
            </Form>
            </TabPane>
        </Tabs>
        <Row align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import Lock from '../lock.vue';
import { getTeamRanking, updateTeamRanking} from '@/api/ballsports/base/team_rankings';
import teamSearch from "../team/search.vue";

export default {
    name: "teamRankingEdit",
    props: {
        sport_id: Number,   // 运动id
        team_ranking_id: Number,   // 联赛id
        team_ranking_type: Number,
    },
    components: {
        Lock,
        teamSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            obj_type: 7,  // 战队
            type: 1,     // 基本数据
            spinShow: false,
            data: {
                id: 0,
                sport_id: self.sport_id,
                team: {},
                deleted: 0,
            },
            locks: {},
            new_team: {
                "id": 0,
                "name": '',
                "logo": '',
            },
        }
    },
    methods: {
        getData () {
            let self = this;
            if (self.team_ranking_id && self.team_ranking_id > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    sport_id: this.sport_id,
                    "id" : this.team_ranking_id,
                    "type": this.team_ranking_type
                }
                getTeamRanking( params ).then(response => {
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                            // self.locks = response.data.data.locks ;
                            self.locks = {}
                        }else{
                            this.data = {}
                            self.locks = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
                self.locks = {}
                self.data = {
                    id: 0,
                    sport_id: self.sport_id,
                    team: {},
                    deleted: 0,
                }
            }
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },

        save() {
            let self = this;

            let params = {
                sport_id:  self.sport_id,
                id: self.team_ranking_id,
                type: self.team_ranking_type,
                team_id: self.data.team.id,
                // deleted: self.data.deleted,
            }
            updateTeamRanking(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
            
        },
        // end
    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        "team_ranking_id":{
            handler(value){
                let self = this;
                if (value >= 0){
                    // console.log("Detail Get Data", value);
                    // self.getCategorys();
                    self.getData();
                };
            },
	        immediate: true,  // 深度触发
        }
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>