import http from '@/utils/http';

// 获取列表
export const getTeamRanking = (params) => {
    return http.request({
        url: '/v1/ballsports/base/team_rankings/list',
        method: 'get',
        params: params
    })
}

// 更新
export const updateTeamRanking = (params) => {
    return http.request({
        url: "/v1/ballsports/base/team_rankings/update",
        method: 'post',
        data: params,
    })
}